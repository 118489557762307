import React, { useState, useEffect } from 'react'

import { getLeadingZeroNumber } from '../../helpers/stringHelper'

const Clock = () => {
  const [hour, setHour] = useState(0)
  const [minute, setMinute] = useState(0)

  const timerCallback = () => {
    const date = new Date()

    if (hour !== date.getHours() || minute !== date.getMinutes()) {
      setHour(date.getHours())
      setMinute(date.getMinutes())
    }
  }

  useEffect(() => {
    let timerInteral = setInterval(timerCallback, 1000)
    return function () {
      clearInterval(timerInteral)
    }
  })

  return (
    <div>
      {getLeadingZeroNumber(hour, 2)}:{getLeadingZeroNumber(minute, 2)}
    </div>
  )
}

export default Clock
