import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'
import screenfull from 'screenfull'
import { navigate } from 'gatsby'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import { useIntl, FormattedMessage } from 'gatsby-plugin-intl'

import Layout from '../../components/common/layout'
import Seo from '../../components/common/seo'
import Clock from '../common/clock'
import Button from '../../components/common/button'

import { EnvConfig } from '../../config/EnvConfig'
import { AuthContext } from '../../context/authProvider'
import { getCasinoToken, cashoutVault } from '../../adapters/user'
import useDeviceDetect from '../../hooks/useDeviceDetect'

import CashinCashbackIcon from '../../images/icon_game_cashincashback.png'
import TimesWhiteIcon from '../../images/icon_times_white.svg'
import CompressIcon from '../../images/icon_compress.svg'
import ExpandIcon from '../../images/icon_expand.svg'

import 'react-circular-progressbar/dist/styles.css'

import toast from '../../components/common/toast'
import FormatAmount from '../../components/common/formatAmount'

const Times = styled.img`
  width: 12px;
  cursor: pointer;
`

const Expand = styled.img`
  width: 15px;
  cursor: pointer;
`

const Compress = styled.img`
  width: 15px;
  cursor: pointer;
`

const GameContainer = styled.div`
  color: #fff;
  background-color: #000;
  background-size: cover;
  background-position: 50%;
  height: 100%;
  overflow: hidden;
  position: relative;
  min-height: 100vh;
  min-height: calc((var(--vh, 1vh) * 100) - 60px);
`

const Col = styled.div`
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  display: flex;
`

const GameWrapper = styled.div`
  height: 100vh;
  height: calc((var(--vh, 1vh) * 100) - 60px);
  position: relative;
  display: flex;
  align-items: center;
`

const IframeWrapper = styled.div`
  background-color: transparent;
  position: relative;
  display: flex;
`

const Time = styled.div`
  position: absolute;
  bottom: -35px;
`

const GameDescription = styled.div`
  max-width: 800px;
  margin: 0 auto;
  width: 100%;
`

const GameTitle = styled.h1`
  font-size: 1.2em;
  font-weight: 600;
`

const GameInfo = styled.p`
  font-size: 0.9em;
  line-height: 20px;
  margin: 0.5rem 0 1.5rem 0;
`

const GameActions = styled.div`
  position: absolute;
  top: 16px;
  right: 30px;
`

const Amount = styled.div`
  font-size: 2em;
  line-height: 50px;
`

const VaultCounter = styled.div`
  width: 210px;
  min-height: 300px;
  border-radius: 4px;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.4);
  text-align: center;
  position: absolute;
  right: -230px;
  box-sizing: border-box;

  h1 {
    text-transform: uppercase;
    color: #fad749;
    font-size: 0.7em;
    letter-spacing: 0.93px;
    font-weight: 400;
  }
`

const Progress = styled.div`
  background: url(${CashinCashbackIcon}) 50% no-repeat;
  background-size: 85px;

  .CircularProgressbar {
    width: 70%;
    margin: 0.5rem 0;
  }

  .CircularProgressbar-trail {
    stroke: #343434;
  }

  .CircularProgressbar-path {
    stroke: #31c27c;
  }
`

const Btn = styled.button`
  display: block;
  width: 30px;
  height: 30px;
  border-radius: 4px;
  background-color: #000;

  &:first-child {
    margin: 0 0 0.5rem 0;
  }

  svg {
    path {
      fill: #fff;
    }
  }
`

const CasinoGame = ({
  pageContext: { title, description, desktopSlug, mobileSlug },
}) => {
  const DEFAULT_SCALED_GAME_PERCENT_HEIGHT = 85

  const gamesLanguages = {
    png: {
      en: 'en',
      pe: 'es',
      pt: 'pt-br',
    },
    '*:*': {
      en: 'EN',
      fi: 'FI',
      no: 'NO',
      ca: 'ENG',
      pe: 'ES',
      ie: 'EN',
      nz: 'EN',
      fr: 'EN',
      cl: 'ES',
      mx: 'ES',
      pl: 'PL',
      pt: 'PT-BR',
    },
  }

  const intl = useIntl()
  const { isMobile } = useDeviceDetect()
  const { isLoggedIn, getUserWallet, user } = useContext(AuthContext)

  const language = gamesLanguages['*:*'][intl.locale]

  const [token, setToken] = useState(null)
  const [placeholder, setPlaceholder] = useState({
    width: 0,
    height: 0,
    scaledW: 0,
    scaledH: 0,
  })

  const getContainerSize = () => {
    return {
      containerWidth: document.documentElement.clientWidth,
      containerHeight: document.documentElement.clientHeight - 60,
    }
  }

  const getPlaceholderSize = (
    suggestedWidth = 1366,
    suggestedHeight = 768,
    containerWidth,
    containerHeight
  ) => {
    let result = {
      width: 0,
      height: 0,
      scaledW: 0,
      scaledH: 0,
    }

    if (typeof window !== 'undefined') {
      if (suggestedWidth > 0 && suggestedHeight > 0) {
        let _width = containerHeight / (suggestedHeight / suggestedWidth)
        let _height = containerWidth / (suggestedWidth / suggestedHeight)

        if (_height < containerHeight) {
          result = Object.assign(result, {
            height: _height,
            width: containerWidth,
          })
        } else {
          result = Object.assign(result, {
            height: containerHeight,
            width: _width,
          })
        }

        result = Object.assign(result, {
          scaledW: (result.width * DEFAULT_SCALED_GAME_PERCENT_HEIGHT) / 100,
          scaledH: (result.height * DEFAULT_SCALED_GAME_PERCENT_HEIGHT) / 100,
        })
      }
      return result
    }
  }

  const cashout = () => {
    if (user.wallet.vault_balance === 0) return

    const model = {
      amount: user.wallet.vault_balance,
    }

    cashoutVault(model)
      .then((_) => {
        getUserWallet()
        toast.success(intl.formatMessage({ id: 'vault.pointsClaimed' }))
      })
      .catch((_) => {})
  }

  useEffect(() => {
    const setGameSize = () => {
      const { containerWidth, containerHeight } = getContainerSize()
      return getPlaceholderSize(1366, 768, containerWidth, containerHeight)
    }

    const placeholderSize = setGameSize()
    setPlaceholder(placeholderSize)

    let timeoutId = null
    const resizeListener = () => {
      clearTimeout(timeoutId)
      const placeholderSize = setGameSize()

      timeoutId = setTimeout(() => setPlaceholder(placeholderSize), 10)
    }

    window.addEventListener('resize', resizeListener)

    return () => {
      window.removeEventListener('resize', resizeListener)
    }
  }, [])

  useEffect(() => {
    const fetchToken = async () => {
      const response = await getCasinoToken()
      if (response.ok) {
        setToken(response.data.data)
      }
    }

    if (isLoggedIn && !token) {
      fetchToken()
    }
  }, [token, isLoggedIn])

  useEffect(() => {
    const walletCallback = () => {
      getUserWallet()
    }

    let walletInterval

    if (isLoggedIn) {
      walletInterval = setInterval(walletCallback, 10000)
    }

    return function () {
      clearInterval(walletInterval)
    }
  }, [isLoggedIn, getUserWallet])

  return (
    <Layout>
      <Seo title={`${title}`} />
      <GameContainer>
        <Col>
          <GameWrapper>
            <IframeWrapper
              style={{
                width: `${placeholder.scaledW}px`,
                height: `${placeholder.scaledH}px`,
              }}
            >
              <iframe
                id='game'
                title={`${title}`}
                style={{
                  width: `${placeholder.scaledW}px`,
                  height: `${placeholder.scaledH}px`,
                }}
                src={`${EnvConfig.GAME_LAUNCH_URL}/${
                  isMobile ? mobileSlug : desktopSlug
                }/open?playMode=${
                  isLoggedIn ? 'REAL' : 'FUN'
                }&languageCode=${language}&lobbyUrl=${EnvConfig.SITE_URL}${
                  isLoggedIn ? `&token=${token}` : ''
                }`}
              />
              <Time style={{ left: `${placeholder.scaledW - 55}px` }}>
                <Clock />
              </Time>

              {!isMobile && user && (
                <VaultCounter>
                  <h1>
                    <FormattedMessage id='casino.yourEarnings' />
                  </h1>

                  <Progress>
                    <CircularProgressbar
                      minValue={0}
                      maxValue={100}
                      strokeWidth={4}
                      value={((user?.wallet?.vault_balance % 1) * 100).toFixed(
                        0
                      )}
                      styles={buildStyles({
                        pathTransitionDuration: 1,
                      })}
                    />
                  </Progress>

                  <Amount>
                    <FormatAmount
                      amount={user?.wallet?.vault_balance}
                      currency={user?.wallet?.currency?.short_code || 'BRL'}
                    />
                  </Amount>

                  <Button
                    text={intl.formatMessage({ id: 'vault.cashout' })}
                    bgcolor='#31c27c'
                    bordercolor='31c27c'
                    className={
                      user?.wallet?.vault_balance === 0 ? 'disabled' : ''
                    }
                    disabled={user?.wallet?.vault_balance === 0}
                    padding='.25rem 1rem'
                    margin='.5rem 0'
                    onClick={cashout}
                  />
                </VaultCounter>
              )}
            </IframeWrapper>
          </GameWrapper>
          <GameDescription>
            <GameTitle>{title}</GameTitle>
            <GameInfo>{description}</GameInfo>
          </GameDescription>
          <GameActions>
            <Btn type='button' onClick={() => navigate(-1)}>
              <Times src={TimesWhiteIcon} alt='Close game' />
            </Btn>
            <Btn type='button'>
              {!screenfull.isFullscreen && (
                <Expand
                  src={ExpandIcon}
                  alt='Open game fullscreen'
                  onClick={() => screenfull.toggle()}
                />
              )}
              {screenfull.isFullscreen && (
                <Compress
                  src={CompressIcon}
                  alt='Close game fullscreen'
                  onClick={() => screenfull.toggle()}
                />
              )}
            </Btn>
          </GameActions>
        </Col>
      </GameContainer>
    </Layout>
  )
}

export default CasinoGame
